import React, { useState } from "react";
import { Switch, Route, Redirect, BrowserRouter, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import LandingPage from "./pages/LandingPage";
import DynamicPage from "./pages/DynamicPage";
import { GA_EVENTS } from "./utils/constants";
import { PAGE_TYPES, PAGES_CONTENT, ROUTES } from "./utils/pages-content";
import Config from "./Config";
import { Navbar } from "./components/Navbar";
import InvestorsPage from "./pages/InvestorsPage";

if (Config.ANALYTICS_ENABLED) {
  ReactGA.initialize(Config.GOOGLE_ANALYTICS_APP_ID);
}

let previousPathname = null;

const MainNavBar = ({ children }) => {
  const location = useLocation();

  const [pageType, setPageType] = useState(null);

  React.useEffect(() => {
    console.log("MainNavBar.useEffect executed");

    const pathname =
      location == null || location.pathname == null || typeof location.pathname != "string" ? null : location.pathname;

    if (pathname !== previousPathname) {
      console.log("Pathname changed: ", pathname);

      previousPathname = pathname;

      if (pathname != null) {
        for (let pageType in ROUTES) {
          if (ROUTES[pageType] === pathname) {
            setPageType(pageType);
            break;
          }
        }

        let eventName = GA_EVENTS.PAGE_VISIT.eventName;

        if (pathname.length !== 0 && pathname !== "/") {
          eventName += location.pathname.replaceAll("/", "-");
        }

        ReactGA.event(eventName);
      }
    }
  }, [location, location.pathname]);

  return (
    <div className="main-container">
      <Navbar pageType={pageType} />
      {children}
    </div>
  );
};

const App = () => {
  console.log("App.render executed");

  return (
    <BrowserRouter>
      <MainNavBar>
        <Switch>
          <Route
            exact
            path={ROUTES[PAGE_TYPES.LANDING]}
            render={(props) => {
              return <LandingPage {...props} pageData={{ type: PAGE_TYPES.LANDING }} />;
            }}
          />

          <Route
            exact
            path={ROUTES[PAGE_TYPES.CAFE]}
            render={(props) => {
              return <DynamicPage {...props} pageData={PAGES_CONTENT[PAGE_TYPES.CAFE]} />;
            }}
          />

          <Route
            exact
            path={ROUTES[PAGE_TYPES.PET_GROOMING]}
            render={(props) => {
              return <DynamicPage {...props} pageData={PAGES_CONTENT[PAGE_TYPES.PET_GROOMING]} />;
            }}
          />

          <Route
            exact
            path={ROUTES[PAGE_TYPES.SALON]}
            render={(props) => {
              return <DynamicPage {...props} pageData={PAGES_CONTENT[PAGE_TYPES.SALON]} />;
            }}
          />

          <Route exact path={ROUTES[PAGE_TYPES.INVESTORS]} component={InvestorsPage} />

          {/* Redirect unmatched routes to Landing page */}
          <Redirect to="/" />
        </Switch>
      </MainNavBar>
    </BrowserRouter>
  );
};

export default App;
