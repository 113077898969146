import React from "react";
import InvestorsPageHeader from "../components/Investors/InvestorsPageHeader";
import InvestorsPageFeatures1 from "../components/Investors/InvestorsPageFeatures1";
import InvestorsPageFeatures2 from "../components/Investors/InvestorsPageFeatures2";
import InvestorsPageInsights from "../components/Investors/InvestorsPageInsights";
import InvestorsPageMission from "../components/Investors/InvestorsPageMission";
import InvestorsPageFooter from "../components/Investors/InvestorsPageFooter";

function InvestorsPage() {
  return (
    <main className="page-container">
      <InvestorsPageHeader />
      <InvestorsPageFeatures1 />
      <InvestorsPageInsights />
      <InvestorsPageFeatures2 />
      <InvestorsPageMission />
      <InvestorsPageFooter />
    </main>
  );
}

export default InvestorsPage;
