import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(() => window.innerWidth);

  const debouncedResizeHandler = useCallback(
    _.debounce(() => {
      setScreenWidth(window.innerWidth);
    }, 250),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      debouncedResizeHandler.cancel();
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, [debouncedResizeHandler]);

  return { screenWidth };
}

export default useScreenWidth;
