/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../styles/components/navbar.scss";
import { useEffect, useState } from "react";
import Config from "../Config";
import { appendParamsFromCurrentURL } from "../utils/helpers";
import ReactGA from "react-ga4";
import { GA_EVENTS, SHOWCASE_CARDS, SOCIAL_MEDIA_ICONS } from "../utils/constants";
import { PAGE_TYPES, ROUTES } from "../utils/pages-content";
import { Dropdown, Menu } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useScreenWidth from "../hooks/useScreenWidth";

const NAVBAR_MOBILE_BREAKPOINT = 850;

let scrollTimeout = null;
let mobileNavTimeout = null;

function clearScrollTimeout() {
  if (scrollTimeout != null) {
    clearTimeout(scrollTimeout);
    scrollTimeout = null;
  }
}
function clearMobileNavTimeout() {
  if (mobileNavTimeout != null) {
    clearTimeout(mobileNavTimeout);
    mobileNavTimeout = null;
  }
}

export function Navbar({ pageType }) {
  const history = useHistory();
  const { screenWidth } = useScreenWidth();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isBusinessesAccordianOpen, setIsBusinessesAccordianOpen] = useState(false);

  console.log("NavBar.currentPageType:", pageType);

  useEffect(() => {
    console.log("NavBar.useEffect executed.");

    const header = document.getElementById("header");
    const navbar = document.getElementById("navbar-section");

    if (header && navbar) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];

          if (entry.isIntersecting) {
            navbar.classList.remove("sticky-navbar");
          } else {
            navbar.classList.add("sticky-navbar");
          }
        },
        {
          root: null, // null means the viewport is the reference for visibility
          threshold: pageType === PAGE_TYPES.LANDING ? 0.05 : 0.3,
        }
      );

      observer.observe(header);

      return () => {
        observer.disconnect();
      };
    }
  }, [pageType]);

  useEffect(() => {
    return () => {
      clearScrollTimeout();
      clearMobileNavTimeout();
    };
  }, []);

  const handleLogoClicked = () => {
    if (window.location.pathname !== "/" && !isInvestorsPage()) {
      history.push("/");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFeaturesSelected = () => {
    ReactGA.event(GA_EVENTS.NAVBAR_FEATURES.eventName);

    let scrollDelay = 0;

    if (window.location.pathname !== "/") {
      history.push("/");
      scrollDelay = 300;
    }

    scrollToSection("insights-section", scrollDelay);
  };

  const scrollToSection = (elementId, scrollDelay = 0) => {
    clearScrollTimeout();
    scrollTimeout = setTimeout(() => {
      const sectionElement = document.getElementById(elementId);
      if (sectionElement != null) {
        const offset = sectionElement.getBoundingClientRect().top + window.scrollY + 0;
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    }, scrollDelay);
  };

  const handlePricingSelected = () => {
    ReactGA.event(GA_EVENTS.NAVBAR_PRICING.eventName);
    scrollToSection("pricing");
  };

  const openMobileNavigation = () => {
    document.body.style.overflow = "hidden";
    setIsMobileNavOpen(true);
  };

  const closeMobileNavigation = (animate = false) => {
    document.body.style.overflow = "auto";

    const mobileNavElement = document.getElementById("mobile-nav");
    if (mobileNavElement != null) {
      mobileNavElement.classList.remove("forward-animation");
      if (animate) {
        mobileNavElement.classList.add("reverse-animation");
      }
    }

    clearMobileNavTimeout();

    if (animate) {
      mobileNavTimeout = setTimeout(() => {
        setIsMobileNavOpen(false);
      }, 800);
    } else {
      setIsMobileNavOpen(false);
    }
  };

  const handleMenuClick = (e) => {
    const selectedMenuItemId = +e.key;
    const selectedCard = SHOWCASE_CARDS.find((card) => card.id === selectedMenuItemId);

    if (selectedCard) {
      if (window.location.pathname !== selectedCard.redirectLink) {
        history.push(selectedCard.redirectLink);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  const menuItems = [
    { key: SHOWCASE_CARDS[0].id, label: SHOWCASE_CARDS[0].text },
    { key: SHOWCASE_CARDS[1].id, label: SHOWCASE_CARDS[1].text },
    { key: SHOWCASE_CARDS[2].id, label: SHOWCASE_CARDS[2].text },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  const isInvestorsPage = () => {
    return window.location.pathname.startsWith(ROUTES[PAGE_TYPES.INVESTORS]);
  };

  return (
    <>
      <div id="navbar-section" className="navbar-section">
        <nav
          id="navbar"
          className={`navbar ${
            screenWidth <= NAVBAR_MOBILE_BREAKPOINT && isInvestorsPage() ? "navbar--center" : "navbar--between"
          }`}
        >
          <div className="navbar-logo">
            <img
              onClick={handleLogoClicked}
              className="navbar-logo__image"
              src="https://assets.boomingo.io/boomingo/website/images/app/logo.png"
              alt="Boomingo"
            />
          </div>

          {!isInvestorsPage() && (
            <>
              {screenWidth > NAVBAR_MOBILE_BREAKPOINT ? (
                <ul className="navbar__menu">
                  <li className="navbar__menu__item">
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a onClick={(e) => e.preventDefault()} className="navbar__menu__link navbar__menu__link--1">
                        BUSINESSES
                      </a>
                    </Dropdown>
                  </li>
                  <li className="navbar__menu__item">
                    <p onClick={handleFeaturesSelected} className="navbar__menu__link navbar__menu__link--1">
                      FEATURES
                    </p>
                  </li>
                  <li className="navbar__menu__item">
                    <p onClick={handlePricingSelected} className="navbar__menu__link">
                      PRICING
                    </p>
                  </li>
                  <li className="navbar__menu__item">
                    <a
                      className="navbar__menu__link"
                      href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        ReactGA.event(GA_EVENTS.LOGIN.eventName);
                      }}
                    >
                      LOGIN
                    </a>
                  </li>
                  <li className="navbar__menu__item navbar__menu__item--btn">
                    <a
                      className="navbar__menu__btn"
                      href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        ReactGA.event(GA_EVENTS.SIGNUP.eventName);
                      }}
                    >
                      SIGN UP
                    </a>
                  </li>
                </ul>
              ) : (
                <img
                  onClick={openMobileNavigation}
                  className="navbar__menu-btn"
                  src="https://assets.boomingo.io/boomingo/website/images/app/menu.svg"
                  alt="menu"
                />
              )}
            </>
          )}
        </nav>
      </div>

      {isMobileNavOpen && (
        <div id="mobile-nav" className="mobile-nav forward-animation">
          <div className="mobile-nav__content">
            <div className="mobile-nav__top-content">
              <div className="mobile-nav__top-content__row-1">
                <img
                  onClick={() => {
                    closeMobileNavigation(true);
                    handleLogoClicked();
                  }}
                  className="mobile-nav__logo"
                  src="https://assets.boomingo.io/boomingo/website/images/app/logo.png"
                  alt="boomingo"
                />
                <img
                  onClick={() => {
                    closeMobileNavigation(true);
                  }}
                  className="mobile-nav__close-icon"
                  src="https://assets.boomingo.io/boomingo/website/images/app/close.png"
                  alt="close"
                />
              </div>
              <div className="mobile-nav__top-content__row-2">
                <ul>
                  <li className="">
                    <p
                      onClick={() => {
                        closeMobileNavigation(true);
                        handleFeaturesSelected();
                      }}
                      className="mobile-nav__list-item"
                    >
                      Features
                    </p>
                  </li>
                  <li>
                    <p
                      onClick={() => {
                        closeMobileNavigation(true);
                        handlePricingSelected();
                      }}
                      className="mobile-nav__list-item"
                    >
                      Pricing
                    </p>
                  </li>
                  <li>
                    <a
                      className="mobile-nav__list-item"
                      href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        ReactGA.event(GA_EVENTS.LOGIN.eventName);
                        closeMobileNavigation(true);
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      className="mobile-nav__list-item"
                      href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        ReactGA.event(GA_EVENTS.SIGNUP.eventName);
                        closeMobileNavigation(true);
                      }}
                    >
                      Sign Up
                    </a>
                  </li>
                  <li className="sidebar-collapse">
                    <a
                      className="mobile-nav__list-item mobile-nav__list-item--no-border"
                      target="_self"
                      rel="noopener noreferrer"
                      onClick={() => {
                        setIsBusinessesAccordianOpen((prevState) => !prevState);
                      }}
                    >
                      Businesses
                    </a>
                    {isBusinessesAccordianOpen ? <UpOutlined /> : <DownOutlined />}
                  </li>

                  <div className="sidebar-collapse-list sidebar-collapse-list__menu">
                    {isBusinessesAccordianOpen &&
                      menuItems.map((item) => (
                        <li key={item.key} className="sidebar-collapse-list__item">
                          <a
                            className="mobile-nav__list-item mobile-nav__list-item--2"
                            target="_self"
                            rel="noopener noreferrer"
                            onClick={() => {
                              if (isMobileNavOpen) {
                                closeMobileNavigation(true);
                              }
                              handleMenuClick(item);
                            }}
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                  </div>
                </ul>
              </div>
            </div>
            <div className="mobile-nav__bottom-content">
              <div className="mobile-nav__bottom-content__row-1">
                {SOCIAL_MEDIA_ICONS?.map((icon, index) => (
                  <a key={index} href={icon.profileUrl} target="_blank" rel="noopener noreferrer">
                    <img className="social-link-icon" src={icon.iconUrl} alt={icon.platform} />
                  </a>
                ))}
              </div>
              <div className="mobile-nav__bottom-content__row-2">
                <p>
                  <span className="footer__copyright-text footer__copyright-text--1">© All rights reserved.</span>
                  <span className="footer__copyright-text footer__copyright-text--2">
                    {" "}
                    {window.location.host.slice(0, 1).toUpperCase() + window.location.host.slice(1)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
