import React from "react";
import HeaderText1 from "../components/HeaderText1";
import FeaturesHeading from "../components/FeaturesHeading";

export const PAGE_TYPES = {
  LANDING: "LANDING",
  CAFE: "CAFE",
  PET_GROOMING: "PET_GROOMING",
  SALON: "SALON",
  INVESTORS: "INVESTORS",
};

export const ROUTES = {
  [PAGE_TYPES.LANDING]: "/",
  [PAGE_TYPES.CAFE]: "/cafes",
  [PAGE_TYPES.PET_GROOMING]: "/pet-grooming",
  [PAGE_TYPES.SALON]: "/salons",
  [PAGE_TYPES.INVESTORS]: "/investors",
};

export const PAGES_CONTENT = {
  [PAGE_TYPES.CAFE]: {
    type: PAGE_TYPES.CAFE,
    bannerImage: "https://assets.boomingo.io/boomingo/website/images/app/banner_cafe.png",
    headerText1: <HeaderText1 pageType={PAGE_TYPES.CAFE} />,
    headerText2: "Our mobile wallet marketing helps cafés keep customers and grow revenue.",
    headerText3: "All this, for the price of two café orders!",
    featuresHeading: <FeaturesHeading pageType={PAGE_TYPES.CAFE} />,
    feature1Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-cafe-1.png",
    feature2Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-cafe-2.png",
    feature3Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-cafe-3.png",
  },
  [PAGE_TYPES.PET_GROOMING]: {
    type: PAGE_TYPES.PET_GROOMING,
    bannerImage: "https://assets.boomingo.io/boomingo/website/images/app/banner_pet_grooming.png",
    headerText1: <HeaderText1 pageType={PAGE_TYPES.PET_GROOMING} />,
    headerText2: "Keep tails wagging and your schedule full with grooming reminders that boost loyalty",
    headerText3: "One grooming visit covers your subscription!",
    featuresHeading: <FeaturesHeading pageType={PAGE_TYPES.PET_GROOMING} />,
    feature1Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-pet-1.png",
    feature2Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-pet-2.png",
    feature3Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-pet-3.png",
  },
  [PAGE_TYPES.SALON]: {
    type: PAGE_TYPES.SALON,
    bannerImage: "https://assets.boomingo.io/boomingo/website/images/app/banner_salon.png",
    headerText1: <HeaderText1 pageType={PAGE_TYPES.SALON} />,
    headerText2: "Boost your salon's revenue and customer loyalty with automated reminders for their next appointments.",
    headerText3: "One client visit covers your subscription!",
    featuresHeading: <FeaturesHeading pageType={PAGE_TYPES.SALON} />,
    feature1Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-salon-1.png",
    feature2Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-salon-2.png",
    feature3Image: "https://assets.boomingo.io/boomingo/website/images/app/feature-salon-3.png",
  },
};
