import React from "react";
import "../../styles/components/investors-features-card.scss";

function InvestorsFeaturesCard({ heading, subheading }) {
  return (
    <div className="investors-features-card">
      <p className="investors-features-card__text-1">{heading}</p>
      <p className="investors-features-card__text-2">{subheading}</p>
    </div>
  );
}

export default InvestorsFeaturesCard;
