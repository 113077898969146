import React from "react";
import { InvestorsFeaturesCard2 } from "./InvestorsFeaturesCard2";
import "../../styles/components/investors-features-2.scss";

function InvestorsFeatures2() {
  return (
    <section className="investors-features-section">
      <div className="investors-features-2">
        <div className="investors-features-2__heading-container">
          <p className="investors-features-2__heading-container__text-1">Why Boomingo?</p>
          <p className="investors-features-2__heading-container__text-2">
            The Boomingo Solution - Purpose-built to address the challenges business owners face, we designed our product with
            quick onboarding, operational simplicity, and a singular mission: to help businesses retain and grow their customer
            base, driving measurable impact and sustainable value.
          </p>
        </div>
        <div className="investors-features-2__steps">
          <InvestorsFeaturesCard2
            text1="Effortless Onboarding"
            text2="Our solution enables businesses to effortlessly create flows and customer journeys, onboarding new customers, engaging existing ones, and capturing valuable data in the process."
          />
          <InvestorsFeaturesCard2
            text1="Targeted Retention Campaigns"
            text2="Replaces generic marketing with data-driven, personalized, performance and behavior-based outreach to maximize ROI from existing foot traffic."
          />
          <InvestorsFeaturesCard2
            text1="Aggregated Feedback & Insights"
            text2="Centralizes reviews and sentiment analysis, enabling informed improvements to enhance the dining experience."
          />
        </div>
      </div>
    </section>
  );
}

export default InvestorsFeatures2;
