import React from "react";
// import SectionHeading from "../SectionHeading";
import "../../styles/components/investors-features-1.scss";
import InvestorsFeaturesCard from "./InvestorsFeaturesCard";

function InvestorsPageFeatures1() {
  return (
    <div className="investors-features-1">
      {/* <SectionHeading
        heading="Revolutionizing Customer Retention For Businesses"
        subheading="Every year, independent businesses lose billions in potential revenue due to customer churn. With retention rates as low as 55%, businesses."
      /> */}
      <div className="investors-features-1__content">
        <InvestorsFeaturesCard
          heading="51% of Visitors Never Return &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          subheading="The highest attrition rate across industries"
        />
        <InvestorsFeaturesCard
          heading="6X More Costly to Acquire New Customers"
          subheading="Retaining customers is far more affordable and impactful."
        />
        <InvestorsFeaturesCard
          heading="$100 Billion Lost Annually to Churn"
          subheading="An untapped opportunity waiting for action."
        />
      </div>
    </div>
  );
}

export default InvestorsPageFeatures1;
