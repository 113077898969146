import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "../../styles/components/investors-insights.scss";
import useScreenWidth from "../../hooks/useScreenWidth";

const DESKTOP_BREAKPOINT = 1024;

function InvestorsPageInsights() {
  const { screenWidth } = useScreenWidth();

  return (
    <div className="investors-insights">
      <SectionHeading
        heading="Boomingo: Empowering Businesses To Succeed"
        subheading="Boomingo solves these everyday challenges by enabling businesses to transform walk-in guests into loyal, repeat and regular customers."
      />
      <div className="investors-insights__content">
        <img
          className="investors-insights__img"
          src={`${
            screenWidth <= DESKTOP_BREAKPOINT
              ? "https://assets.boomingo.io/boomingo/website/images/investors/investors-insights-mobile-v2.png"
              : "https://assets.boomingo.io/boomingo/website/images/investors/investors-insights-web-v2.png"
          }`}
          alt=""
        />
      </div>
    </div>
  );
}

export default InvestorsPageInsights;
