import React from "react";
import "../../styles/components/investors-features-card-2.scss";

export const InvestorsFeaturesCard2 = ({ text1, text2 }) => {
  return (
    <div className="investors-features-card-2">
      <img
        className="investors-features-card-2__icon"
        src="https://assets.boomingo.io/boomingo/website/images/app/full-stop-icon.svg"
        alt=""
      />
      <p className="investors-features-card-2__text-1">{text1}</p>
      <p className="investors-features-card-2__text-2">{text2}</p>
    </div>
  );
};
