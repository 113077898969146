import React from "react";
import "../styles/components/shared.scss";

function SectionHeading({ heading, subheading }) {
  return (
    <div className="section-heading__container">
      <p className="section-heading__text-1">{heading}</p>
      <p className="section-heading__text-2">{subheading}</p>
    </div>
  );
}

export default SectionHeading;
