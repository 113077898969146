import React from "react";
import "../../styles/components/investors-footer.scss";
import Config from "../../Config";

function InvestorsPageFooter() {
  const handleEmailClick = () => {
    window.location.href = `mailto:${Config.INVESTORS_EMAIL_ADDRESS}`;
  };

  return (
    <div className="investors-footer">
      <div className="investors-footer__row">
        <div className="investors-footer__col">
          <p className="investors-footer__text-1">Join The Movement</p>
          <p className="investors-footer__text-2">Ready to disrupt the status quo and join us in our journey?</p>
          <p className="investors-footer__text-3">Contact us today!</p>
        </div>
        <div
          className="investors-footer__btn"
          onClick={handleEmailClick}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
        >
          <span className="investors-footer__email">{Config.INVESTORS_EMAIL_ADDRESS}</span>
        </div>
      </div>
    </div>
  );
}

export default InvestorsPageFooter;
