import React from "react";
import SectionHeading from "../SectionHeading";
import "../../styles/components/investors-mission.scss";

function InvestorsPageMission() {
  return (
    <div className="investors-mission">
      <SectionHeading
        heading="Our Mission"
        subheading="We aim to give control back to business owners. Boomingo empowers independent businesses with the tools to engage with their customers, retain their loyalty, and grow their business sustainably."
      />

      <img
        className="investors-mission__image"
        src="https://assets.boomingo.io/boomingo/website/images/investors/investors-hero-image.png"
        alt=""
      />
    </div>
  );
}

export default InvestorsPageMission;
