import React from "react";
import "../../styles/components/investors-page-header.scss";
import useScreenWidth from "../../hooks/useScreenWidth";

function InvestorsPageHeader() {
  const { screenWidth } = useScreenWidth();

  return (
    <div id="header" className="investors-page-header">
      {/* <p className="investors-page-header__text--variant-1 ">Your Guests, Your Data, Your Growth—All On Autopilot.</p> */}

      <p className="investors-page-header__text--variant-2 investors-page-header__text-2">
        <span>All-in-one platform that</span> {screenWidth <= 700 && <br />}
        <span>digitizes footfall for</span> <br />
        <span>physical retail outlets</span>
      </p>

      <p className="investors-page-header__text--variant-3 investors-page-header__text-4">
        Every year, independent businesses lose billions in potential revenue due to customer churn. With retention rates as low
        as 55%, businesses face the steepest challenges in customer loyalty. It’s time to flip the script.
      </p>
    </div>
  );
}

export default InvestorsPageHeader;
